import 'bootstrap/dist/css/bootstrap.min.css';
import { NavbarComp } from './components/NavbarComp';
import {Container, Row, Col } from 'react-bootstrap';
import veteran_splash from "./images/VeteransSplash.jpg"
import vet_housing from "./images/vet_housing.jpeg";
import vet_jobs from "./images/veterans_jobs.jpeg";
import vet_health from "./images/vet_hospital.jpeg";
import CardComp from './components/CardComp';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { About } from './pages/About';
import { VeteranJobs } from './models/VeteranJobs';
import { VeteranHealthcare } from './models/VeteranHealthcare';
import { VeteranHousing } from './models/VeteranHousing';
import HealthInstance from './instances/HealthInstance';
import JobsInstance from './instances/JobsInstance';
import HousingInstance from './instances/HousingInstance';
import SearchResults from './pages/SearchResults'
import Visualizations from './pages/Visualizations'
import ProviderVisualizations from './pages/ProviderVisualizations'


function Home() {
    return (
        <div className="Home" style={{ height: '100vh', overflow: 'auto'}}>
            <div className="background-image-section" style={{
                backgroundImage: `url(${veteran_splash})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h1>VeteranAid</h1>
            </div>
            <Container className="mt-5">
                <Row>
                    <Col>
                        <CardComp imgSrc={vet_housing} title="Affordable Housing" description=" Find affordable housing options in New York City " link= '/housing'/>
                    </Col>
                    <Col>
                        <CardComp imgSrc={vet_health} title="Healthcare Facilities" description=" Find hospitals and clinics" link= '/health' />
                    </Col>
                    <Col>
                        <CardComp imgSrc={vet_jobs} title="Jobs" description=" Find in demand careers after service" link = '/jobs' />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function App() {
    return (
        <BrowserRouter>
        <NavbarComp />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/housing" element={<VeteranHousing />} />
            <Route path="/health" element={<VeteranHealthcare />} />
            <Route path="/jobs" element={< VeteranJobs />} />
            <Route path="/housing/:instanceId" element={<HousingInstance />} />
            <Route path="/health/:instanceId" element={<HealthInstance />} />
            <Route path="/jobs/:instanceId" element={<JobsInstance/>} />
            <Route path="/search/:query" element={<SearchResults />} />
            <Route path = "/visualizations" element = {<Visualizations /> } />
            <Route path = "/provider_visualizations" element = {<ProviderVisualizations />} />
        </Routes>
    </BrowserRouter>
    );
}

export default App;