import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InstancePage from '../pages/InstancePage';
import JobsCard from '../components/JobsCard';
import HousingCard from '../components/HousingCard';
import {Row, Col} from "react-bootstrap"

export const HealthInstance = () => {
  let { instanceId } = useParams();
  const [instanceData, setInstanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [relatedJobInstances, setRelatedJobInstances] = useState([]);
  const [relatedHousingInstances, setRelatedHousingInstances] = useState([]);

  useEffect(() => {
    // Construct the URL with the instanceId
    const url = `https://backend.vetsaid.me/api/healthcare/${instanceId}`;

    fetch(url, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        setInstanceData(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Fetch error:', err);
        setError(true);
        setLoading(false);
      });

    const randomHousingInstanceID = Math.floor(Math.random() * 30) + 1;
    const randomJobInstanceID = Math.floor(Math.random() * 30) + 1;

    const jobsInstancesUrl = `https://backend.vetsaid.me/api/jobs/${randomJobInstanceID}`;
    const HousingInstancesUrl = `https://backend.vetsaid.me/api/jobs/${randomHousingInstanceID}`;

    console.log('relatedJobInstances:', relatedJobInstances);
    console.log('relatedHousingInstances:', relatedHousingInstances);

    // Fetch related jobs instances data
    fetch(jobsInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setRelatedJobInstances(data);
      })
      .catch(error => {
        console.error('Error fetching related jobs instances:', error);
      });

    // Fetch related jobs instances data
    fetch(HousingInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setRelatedHousingInstances(data);
      })
      .catch(error => {
        console.error('Error fetching related jobs instances:', error);
      });

    console.log('relatedJobInstances:', relatedJobInstances);
    console.log('relatedHousingInstances:', relatedHousingInstances);
  }, [instanceId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !instanceData) {
    return <div>Instance not found</div>;
  }

  console.log(instanceData);

  const descriptors = [
    { descriptor: "Classification", field: instanceData.data.attributes.classification },
    { descriptor: "Contact", field: instanceData.data.attributes.contact },
    { descriptor: "Type", field: instanceData.data.attributes.ftype },
    { descriptor: "City", field: instanceData.data.attributes.city },
    { descriptor: "State", field: instanceData.data.attributes.state },
  ];
  var description = instanceData.data.attributes.name + ' is a ' + instanceData.data.attributes.classification +
    ' VA Facility located at ' + instanceData.data.attributes.address + '. Their contact information is ' +
    instanceData.data.attributes.contact + ', and their website can be found at ' + instanceData.data.attributes.website;

  return (
    <div>
      <InstancePage
        title={instanceData.data.attributes.name}
        description={description}
        descriptors={descriptors}
        leftSectionText="Nearby jobs"
        rightSectionText="Nearby housing"
        visitSiteLink={instanceData.data.attributes.website}
        lat={instanceData.data.attributes.latitude}
        long={instanceData.data.attributes.longitude}
        imgSrc = {instanceData.data.attributes.url}
        related_instance1={Array.isArray(relatedHousingInstances) ? relatedHousingInstances.map((healthInstance, index) => ({
          content: (
            <HousingCard
              key={index}
              name={healthInstance.attributes.name}
              classification={healthInstance.attributes.classification}
              address={healthInstance.attributes.address}
              city={healthInstance.attributes.city}
              state={healthInstance.attributes.state}
              id={healthInstance.attributes.id}
              img_url={healthInstance.attributes.img_url}
            />
          ),
        })) : []}
        related_instance2={Array.isArray(relatedHousingInstances) ? relatedHousingInstances.map((healthInstance, index) => ({
          content: (
            <HousingCard
              key={index}
              name={healthInstance.attributes.name}
              classification={healthInstance.attributes.classification}
              address={healthInstance.attributes.address}
              city={healthInstance.attributes.city}
              state={healthInstance.attributes.state}
              id={healthInstance.attributes.id}
              img_url={healthInstance.attributes.img_url}
            />
          ),
        })) : []}
        related_instance3={Array.isArray(relatedJobInstances) ? relatedJobInstances.map((jobInstance, index) => ({
          content: (
            <JobsCard
              key={index}
              title={jobInstance.attributes.title}
              pay_freq={jobInstance.attributes.pay_freq}
              posting_date={jobInstance.attributes.posting_date}
              location={jobInstance.attributes.location}
              career_level={jobInstance.attributes.career_level}
              id={jobInstance.attributes.id}
              img_url={jobInstance.attributes.img_url}
            />
          ),
        })) : []}
        related_instance4={Array.isArray(relatedJobInstances) ? relatedJobInstances.map((jobInstance, index) => ({
          content: (
            <JobsCard
              key={index}
              title={jobInstance.attributes.title}
              pay_freq={jobInstance.attributes.pay_freq}
              posting_date={jobInstance.attributes.posting_date}
              location={jobInstance.attributes.location}
              career_level={jobInstance.attributes.career_level}
              id={jobInstance.attributes.id}
              img_url={jobInstance.attributes.img_url}
            />
          ),
        })) : []}
      />
    <Row xs = {5}>
    <Col xs={5}>
        <JobsCard
             title= "CITY RESEARCH SCIENTIST"
             pay_freq = "Annual"
             posting_date = "2023-06-15T00:00:00.000"
             location = "96-05 Horace Harding Expway"
             career_level= "Experienced (non-manager)"
             id = "5"
             img_url= "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQzQVtfhxgaUsKulAVnqPTvJW5ryBODV_lxzSGnV252E6YDNpVrRiuaz68"
        />
        <JobsCard
            title= "JavaScript/ReactJS Developer"
            pay_freq = "Annual"
            posting_date = "2023-08-16T00:00:00.000"
            location = "9 Metrotech Center, Brooklyn N"
            career_level= "Experienced (non-manager)"
            id= "6"
            img_url = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQhdSjFlKXrxtMUggbckB7qPLvwE1Bc-2CuYWE5Gu0TUu7wSGSo-S_Mfde"
        />
    </Col>
    <Col xs={5}>
        <HousingCard
            address="MERMAID AVENUE "
            projectName="Mermaid  / West. 16th Street"
            numUnits="3"
            borough="Brooklyn"
            postcode= "11224"
            id = "7"
            img_url = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQqsr21izO_TxkNhx7nT5ppiRaNjzhG7mqGwtcpHZKJADSVuFEHnkijHEvB"
        />
        <HousingCard
            address=" WEST 108 STREET "
            projectName="WSFSSH. 145 WEST 108TH ST. VALLEY LODGE"
            numUnits="11"
            borough="Manhattan"
            postcode="10025"
            id= "12"
            img_url = "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRUY3402f-4Lp_imuRlD-LkmeklYTo-laIFn1ioeFAPFIkTcpogXQUZVJWj"
        />
    </Col>
    </Row>
    </div>
  );
};

export default HealthInstance;
