import React from 'react';
import { Container } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip} from "recharts"

const data = [
    {
    "name": "Syria",
    "count": 70
    },
    {
    "name": "Bangladesh",
    "count": 1
    },
    {
    "name": "Russia",
    "count": 2
    },
    {
    "name": "Palestine",
    "count": 1
    },
    {
    "name": "Jordan",
    "count": 1
    }

]

const COLORS = ['#840032', '#006595', '#E59500', '#48A9A6', '#D88C9A'];

export const NewsAndEvents = () => {
    return (
        <Container className="d-flex justify-content-center" fluid="md">
                <PieChart width={800} height={500}>
                    <Pie
                        dataKey="count"
                        nameKey="name"
                        data={data}
                        outerRadius={200}
                        label={(entry) => entry.name}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
        </Container>
    )
};

export default NewsAndEvents;