import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Container } from 'react-bootstrap'
import InstancePage  from '../pages/InstancePage';
import { Bar, Chart} from 'react-chartjs-2';
import HealthCard from '../components/HealthCard';
import HousingCard from '../components/HousingCard';
import 'chart.js/auto';


export const JobsInstance = () => {


    let { instanceId } = useParams();  
    const [instanceData, setInstanceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [relatedHealthInstances, setRelatedHealthInstances] = useState([]);
    const [relatedHousingInstances, setRelatedHousingInstances] = useState([]);

    useEffect(() => {
        // Construct the URL with the instanceId
        const url = `https://backend.vetsaid.me/api/jobs/${instanceId}`; 

        fetch(url, {headers: {'Accept': 'application/vnd.api+json'}})
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                setInstanceData(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Fetch error:', err);
                setError(true);
                setLoading(false);
            });



            const randomHealthInstanceID = Math.floor(Math.random() * 30) + 1;
            const randomHousingInstanceID = Math.floor(Math.random() * 30) + 1;
        
            // Construct the URLs with the random instance IDs
            const healthInstancesUrl = `https://backend.vetsaid.me/api/healthcare/${randomHealthInstanceID}`;
            const HousingInstancesUrl = `https://backend.vetsaid.me/api/jobs/${randomHousingInstanceID}`;
        
            console.log('relatedHealthInstances:', relatedHealthInstances);
            console.log('relatedHousingInstances:', relatedHousingInstances);
        
            // Fetch related health instances data
            fetch(healthInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                setRelatedHealthInstances(data);
              })
              .catch(error => {
                console.error('Error fetching related health instances:', error);
              });
        
            // Fetch related jobs instances data
            fetch(HousingInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                setRelatedHousingInstances(data);
              })
              .catch(error => {
                console.error('Error fetching related jobs instances:', error);
              });
        
        
              console.log('relatedHealthInstances:', relatedHealthInstances);
              console.log('relatedHousingInstances:', relatedHousingInstances);
            
    }, [instanceId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !instanceData) {
        return <div>Instance not found</div>;
    }
    console.log(instanceData)

    // Create an array of descriptor objects (similar to previous code)
    const descriptors = [
        { descriptor: "Pay frequency", field: instanceData.data.attributes.pay_frequency },
        { descriptor: "Lowest Recorded Salary", field: instanceData.data.attributes.pay_low_end},
        { descriptor: "Highest Recorded Salary", field: instanceData.data.attributes.pay_high_end },
        { descriptor: "Requirements", field: instanceData.data.attributes.requirements },
        { descriptor: "Residency Requirements", field: instanceData.data.attributes.residency_requirement},
    ];

    const chartData = {
        labels: ['Lowest Salary', 'Highest Salary'],
        datasets: [
          {
            label: 'Salary',
            backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
            hoverBackgroundColor: ['rgba(75, 192, 192, 0.4)', 'rgba(255, 99, 132, 0.4)'],
            hoverBorderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
            data: [instanceData.data.attributes.pay_low_end, instanceData.data.attributes.pay_high_end],
          },
        ],
      };
    
      // Bar chart options (customize as needed)
      const chartOptions = {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Salary',
            },
            type: 'linear',
          },
        },
      };



    return (
        <div>
            {/* <JobsInstancePage
            title={instanceData.data.attributes.title}
            description={instanceData.data.attributes.description}
            location={instanceData.data.attributes.location}
            pay_freq={instanceData.data.attributes.pay_frequency} 
            low={instanceData.data.attributes.pay_low_end}
            high={instanceData.data.attributes.pay_high_end}
            requirements={instanceData.data.attributes.requirements}
            res_requirements={instanceData.data.attributes.residency_requirements}
            post_date={instanceData.data.attributes.posting_date}
            /> */}
             <InstancePage
            title={instanceData.data.attributes.address}
            imgSrc={instanceData.data.attributes.url}
            description={instanceData.data.attributes.description}
            descriptors={descriptors}
            leftSectionText="Nearby housing"
            rightSectionText="Nearby hospitals/clinics"
            visitSiteLink="#"
            related_instance1={Array.isArray(relatedHealthInstances) ? relatedHealthInstances.map((healthInstance, index) => ({
                content: (
                  <HealthCard
                    key={index}
                    name={healthInstance.attributes.name}
                    classification={healthInstance.data.classification}
                    address={healthInstance.attributes.address}
                    city={healthInstance.attributes.city}
                    state={healthInstance.attributes.state}
                    id={healthInstance.attributes.id}
                    img_url={healthInstance.attributes.img_url}
                  />
                ),
              })) : []}
              related_instance2={Array.isArray(relatedHealthInstances) ? relatedHealthInstances.map((healthInstance, index) => ({
                content: (
                  <HealthCard
                    key={index}
                    name={healthInstance.attributes.name}
                    classification={healthInstance.attributes.classification}
                    address={healthInstance.attributes.address}
                    city={healthInstance.attributes.city}
                    state={healthInstance.attributes.state}
                    id={healthInstance.attributes.id}
                    img_url={healthInstance.attributes.img_url}
                  />
                ),
              })) : []}
              related_instance3={Array.isArray(relatedHousingInstances) ? relatedHousingInstances.map((housingInstance, index) => ({
                content: (
                  <HousingCard
                    key={index}
                    title={housingInstance.attributes.title}
                    pay_freq={housingInstance.attributes.pay_freq}
                    posting_date={housingInstance.attributes.posting_date}
                    location={housingInstance.attributes.location}
                    career_level={housingInstance.attributes.career_level}
                    id={housingInstance.attributes.id}
                    img_url={housingInstance.attributes.img_url}
                  />
                ),
              })) : []}
              related_instance4={Array.isArray(relatedHousingInstances) ? relatedHousingInstances.map((housingInstance, index) => ({
                content: (
                  <HousingCard
                    key={index}
                    title={housingInstance.attributes.title}
                    pay_freq={housingInstance.attributes.pay_freq}
                    posting_date={housingInstance.attributes.posting_date}
                    location={housingInstance.attributes.location}
                    career_level={housingInstance.attributes.career_level}
                    id={housingInstance.attributes.id}
                    img_url={housingInstance.attributes.img_url}
                  />
                ),
              })) : []}
            />
            {/* Temporary Solution until we get the related instances working */}
            <Row xs = {5}>
            <Col xs={5}>
            <HousingCard
            address="MERMAID AVENUE "
            projectName="Mermaid  / West. 16th Street"
            numUnits="3"
            borough="Brooklyn"
            postcode= "11224"
            id = "7"
            img_url = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQqsr21izO_TxkNhx7nT5ppiRaNjzhG7mqGwtcpHZKJADSVuFEHnkijHEvB"
        />
        <HousingCard
            address=" WEST 108 STREET "
            projectName="WSFSSH. 145 WEST 108TH ST. VALLEY LODGE"
            numUnits="11"
            borough="Manhattan"
            postcode="10025"
            id= "12"
            img_url = "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRUY3402f-4Lp_imuRlD-LkmeklYTo-laIFn1ioeFAPFIkTcpogXQUZVJWj"
        />
            </Col>
            <Col xs={5}>
            <HealthCard
            id="5"
            name="Harlem VA Clinic"
            classification="Other Outpatient Services (OOS)"
            address="55 West 125th Street"
            city="New York"
            state="NY"
            img_url = "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRpIMbEp1epIOM7ps1FJLbB7rpq2r5k6OllStD0z7Ah-eZEWJ7Fj7c3jAA2"

        />
        <HealthCard
            id="6"
            name="Staten Island Community VA Clinic"
            classification="Multi-Specialty CBOC"
            address="1150 South Avenue"
            city="Staten Island"
            state="NY"
            img_url = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSqAbNQwkA2YF4IgBIPJDQIoVK_cYALJ_cZSdaLFdOPULER0LXEgzSPzOA"
        />
        </Col>
        </Row>
        <Container> {/* Wrap everything in a Bootstrap Container */}
      <div>
        <h1 className="text-center">Lowest vs Highest Salary</h1> {/* Center the header */}
        <Row>
          <Col>
            {/* Insert your logic here */}
            <Chart type="bar" data={chartData} options={chartOptions} />
          </Col>
        </Row>
      </div>
    </Container> {/* Close the Bootstrap Container */}
        </div>
        );
    };

export default JobsInstance;

