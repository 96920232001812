import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PieChart, Treemap, Pie, Cell, Tooltip, ResponsiveContainer, LineChart,
     Line, CartesianGrid, XAxis, YAxis, Scatter, ScatterChart, Legend} from 'recharts';
import housing_data from './HousingVisualization.json';

const COLORS = ['#840032', '#006595', '#E59500', '#48A9A6', '#D88C9A'];

const data = [
    {
        "name": "Primary Care CBOC",
        "count": 26
    },
    {
        "name": "VA Medical Center",
        "count": 11
    },
    {
        "name": "Other Outpatient Services",
        "count": 12
    },
    {
        "name": "Multi-Specialty CBOC",
        "count": 11
    },
    {
        "name": "Residential Care Site",
        "count": 1
    }
]

const BOROUGH_DATA = [
    { name: 'Brooklyn', value: 480 },
    { name: 'Manhattan', value: 197 },
    { name: 'Bronx', value: 181 },
    { name: 'Queens', value: 40 },
    { name: 'Staten Island', value: 2 }
];

const job_data = [{'name': 'Jul 21', 'value': 1}, {'name': 'Sep 21', 'value': 1}, 
{'name': 'Dec 21', 'value': 2}, {'name': 'Jan 22', 'value': 1}, 
{'name': 'Feb 22', 'value': 1}, {'name': 'Mar 22', 'value': 3}, 
{'name': 'Apr 22', 'value': 9}, {'name': 'May 22', 'value': 7}, 
{'name': 'Jun 22', 'value': 18}, {'name': 'Jul 22', 'value': 18}, 
{'name': 'Aug 22', 'value': 16}, {'name': 'Sep 22', 'value': 14}, 
{'name': 'Oct 22', 'value': 16}, {'name': 'Nov 22', 'value': 12}, 
{'name': 'Dec 22', 'value': 13}, {'name': 'Jan 23', 'value': 23}, 
{'name': 'Feb 23', 'value': 17}, {'name': 'Mar 23', 'value': 41}, 
{'name': 'Apr 23', 'value': 26}, {'name': 'May 23', 'value': 39}, 
{'name': 'Jun 23', 'value': 42}, {'name': 'Jul 23', 'value': 86}, 
{'name': 'Aug 23', 'value': 127}, {'name': 'Sep 23', 'value': 134}, 
{'name': 'Oct 23', 'value': 52}]




export const Visualizations = () => {

    const getColor = (borough) => {
        if (borough === "Brooklyn") {
            return "#840032"
        } else if (borough === "Manhattan") {
            return "#006595"
        } else if (borough === "Bronx") {
            return "#E59500"
        } else if (borough === "Queens") {
            return "#48A9A6"
        } else if (borough === "Staten Island") {
            return "#D88C9A"
        }
    }


    return (
        <Container fluid="md">
            <Row style={{ width: "100%", height: 600 }}>
                <h3 className="p-5 text-center">Distribution of Healthcare Facilities by Classification</h3>

                <Col>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={800} height={500}>
                            <Pie
                                dataKey="count"
                                nameKey="name"
                                data={data}
                                outerRadius={200}
                                label={(entry) => entry.name}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            <Row style={{ width: "100%", height: 600 }}>
                <h3 className="p-5 text-center">Number of Jobs in Each Borough</h3>
                <Col>
                    <ResponsiveContainer width="100%" height="100%">
                        <Treemap
                            data={BOROUGH_DATA}
                            dataKey="value"
                            ratio={4 / 3}
                            stroke="#fff"
                            isAnimationActive={false}
                            color={(entry, index) => COLORS[index % COLORS.length]}
                        >
                            <Tooltip />
                        </Treemap>
                    </ResponsiveContainer>
                </Col>
            </Row>
            <Row style={{ width: "100%", height: 600 }}>
                <h3 className="p-5 text-center">Number of Jobs Posted by Month and Year</h3>
                <Col>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={500}
                        height={300}
                        data={job_data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
            <Row style={{ width: "100%", height: 600 }}>
                <h3 className="p-5 text-center">Density of Housing by Borough</h3>
                <Col>
                    <ScatterChart
                    width={800}
                    height={800}
                    
                    >
                    <CartesianGrid />
                    <XAxis type="number" dataKey="lon" name="long" domain={[-74.15, -73.65]}/>
                    <YAxis type="number" dataKey="l" name="lat" domain={[40.5, 41]}/>
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    
                    <Scatter name="A school" data={housing_data} fill="#8884d8">
                        {housing_data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={getColor(entry.b)}/>
                        ))}
                    </Scatter>
                    <Legend payload={[{ value: 'Brooklyn', type: "circle", color: '#840032', id: 'ID01' },
                                      { value: 'Manhattan', type: "circle", color: '#006595', id: 'ID01' },
                                      { value: 'Bronx', type: "circle", color: '#E59500', id: 'ID01' },
                                      { value: 'Queens', type: "circle", color: '#48A9A6', id: 'ID01' },
                                      { value: 'Staten Island', type: "circle", color: '#D88C9A', id: 'ID01' } ]}
                            align='right'
                            verticalAlign='middle'
                            layout='vertical' />
                    </ScatterChart>
                </Col>
            </Row>
        </Container>
    )
};

export default Visualizations;
