import React from 'react';
import { Card, Col } from 'react-bootstrap';

const RelatedInstanceComponent = (props) => {
  const { leftHeaderText, rightHeaderText, leftCards, rightCards } = props;
  
  return (
    <React.Fragment>
      {/* Left Section */}
      <Col md={5} className="text-center">
        <h5>{leftHeaderText}</h5>
        <div className="d-flex flex-column">
          {leftCards.map((card, index) => (
            <Card key={index} className="mb-2">
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.content}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Col>

      {/* Right Section */}
      <Col md={5} className="text-center">
        <h5>{rightHeaderText}</h5>
        <div className="d-flex flex-column">
          {rightCards.map((card, index) => (
            <Card key={index} className="mb-2">
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.content}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Col>
    </React.Fragment>
  );
};

export default RelatedInstanceComponent;