import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { highlightText } from '../util/highlightText';

const HousingCard = ({
  address,
  projectName,
  numUnits,
  borough,
  postcode,
  id,
  img_url,
  searchQuery, // New prop for the search query
}) => {
  const [imageURL, setImageURL] = useState('housing_placeholder.png');

  useEffect(() => {
    fetch(img_url)
      .then((response) => response.blob())
      .then((blob) => {
        setImageURL(URL.createObjectURL(blob));
      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }, [img_url]);

  return (
    <Card className="model-card" style={{ height: '400px' }}>
      <Card.Img variant="top" src={imageURL} style={{ height: '150px', objectFit: 'cover' }} />
      <Card.Body className="card-body d-flex flex-column justify-content-between">
        <div>
          <Card.Title style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {highlightText(address, searchQuery)}
          </Card.Title>
          <Card.Text>
            Project Name: {highlightText(projectName, searchQuery)} <br />
            Number of Units: {highlightText(numUnits, searchQuery)} <br />
            Borough: {highlightText(borough, searchQuery)} <br />
            Postcode: {highlightText(postcode, searchQuery)} <br />
          </Card.Text>
        </div>
        <Link to={`/housing/${id}`}>
          <Button variant="primary">Learn More</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default HousingCard;
