import React, { useState } from 'react';

const PaginationComponent = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [visibleStartPage, setVisibleStartPage] = useState(1);

  const handlePageClick = (pageNumber) => {
    if (pageNumber === currentPage) return;
    onPageChange(pageNumber);
  };

  const handleNextSetClick = () => {
    if (visibleStartPage + 8 <= totalPages) {
      setVisibleStartPage(visibleStartPage + 8);
    }
  };

  const handlePrevSetClick = () => {
    if (visibleStartPage - 8 >= 1) {
      setVisibleStartPage(visibleStartPage - 8);
    }
  };

  const visiblePageNumbers = totalPages > 0
    ? [...Array(totalPages)]
        .map((_, idx) => idx + 1)
        .slice(visibleStartPage - 1, visibleStartPage + 7)
    : [];

  return (
    <div className="pagination-container">
      {visibleStartPage > 1 && (
        <button onClick={handlePrevSetClick} className="arrow-button">
          {'<'}
        </button>
      )}
      {visiblePageNumbers.map(number => (
        <button
          key={number}
          onClick={() => handlePageClick(number)}
          className={`page-button ${number === currentPage ? 'active' : ''}`}
        >
          {number}
        </button>
      ))}
      {visibleStartPage + 7 < totalPages && (
        <button onClick={handleNextSetClick} className="arrow-button">
          {'>'}
        </button>
      )}
    </div>
  );
};

export default PaginationComponent;