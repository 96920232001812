import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';

const ax = axios.create({
  baseURL: 'https://api.syrianrefugeecrisis.me/'
});

const CharitiesByCountry = () => {
  const [charitiesData, setCharitiesData] = useState([]);

  function ProcessCharities(data) {
    const countryCount = {};
    data.forEach(charity => {
      const country = charity.hq_country;
      countryCount[country] = (countryCount[country] || 0) + 1;
    });
    return Object.entries(countryCount).map(([country, count]) => ({ name: country, charities: count }));
  }

  useEffect(() => {
    const fetchCharitiesData = async () => {
      try {
        const response = await ax.get('charities');
        setCharitiesData(ProcessCharities(response.data.data));
      } catch (error) {
        console.error('Error fetching charities data:', error);
      }
    };

    fetchCharitiesData();
  }, []);

  return (
    <Container className="d-flex justify-content-center" fluid="md">
      <Row style={{ width: "100%" }}>
        <Col>
          <BarChart width={1200} height={400} data={charitiesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} tick={{ fontSize: 9 }} textAnchor="end" interval={0} height={80} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="charities" fill="#456CD9" />
          </BarChart>
        </Col>
      </Row>
    </Container>
  );
};

export default CharitiesByCountry;
