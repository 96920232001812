import 'bootstrap/dist/css/bootstrap.min.css'
import { team } from "../components/Team.js"
import { PlayerCard } from "../components/CardComp"
import { Card, Container, Row, Col, Button, Stack, ListGroup, ListGroupItem } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import ToolGrid from "../components/ToolGrid"


export const About = () => {

  // The following code (lines 14 - 68) was used from the syrian refugee team (Group 18)
  // It involves extracting the information utilizing gitlab's api in order
  // to get the right info for commits and issues
  const [totalCommits, setTotalCommits] = useState([]);
  const [totalIssues, setTotalIssues] = useState([]);

  // Get commits per user
  async function get_commits() {
    var numCommits = 0;
    var request = new XMLHttpRequest();  
    var url =
      "https://gitlab.com/api/v4/projects/50428998/repository/contributors/";
    request.open("GET", url);
    request.onload = function () {
      var result = JSON.parse(this.response);
      result.forEach((data) => {
        team.forEach((user) => {
          if (data.name === user.name) {
            user.commits = data.commits;
            numCommits += data.commits;
          }
        });
      });
      setTotalCommits(numCommits);
    };
    request.send();
  }

    // Get issues per user
    async function get_issues() {
      team.forEach((user) => {
        var request = new XMLHttpRequest();
        var url =
          "https://gitlab.com/api/v4/projects/50428998/issues_statistics?author_id=".concat(
            user.user_id
          );
        request.open("GET", url);
        request.onload = function () {
          var result = JSON.parse(this.response);
          var num = result.statistics.counts.all;
          user.issues = num;
        };
        request.send();
      });
    }

    // Get total issues
  async function total_issues() {
    var request = new XMLHttpRequest();
    var url = "https://gitlab.com/api/v4/projects/50428998/issues_statistics";
    request.open("GET", url);
    request.onload = function () {
      var result = JSON.parse(this.response);
      var num = result.statistics.counts.all;
      setTotalIssues(num);
    };
    request.send();
  }

  useEffect(() => {
    get_commits();
    get_issues();
    total_issues();
  }, []);


  const renderDescription = () => {
    return (
      <Container className='description p-3 d-flex align-items-stretch text-center'>
        <Card className='about-vetsaid-card'>
          <Card.Body>
            <Card.Title>    
              <h1 className = "about-title text-center" > About Veteran Aid </h1>
            </Card.Title>
            <Card.Text>
              <p>
              VeteranAid aims to facilitate civic engagement, foster community support, and assists veterans in need in New York City. 
              Through our platform, we will bridge gaps in healthcare, housing, and jobs by providing resources for veterans to gain footholds back into society. 
              We will highlight stories from the community, provide data on current conditions, and offer a directory of opportunities to help with time, talent, or funds.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  }


  const renderTeam = () => {
    return team.map((member, index) => {
      return (
        <Col key={index} md={3}>
          <PlayerCard 
            imgSrc={member.image} 
            name={member.display_name} 
            role={member.role} 
            description={member.bio} 
            commits={member.commits || 0} 
            issues={member.issues || 0}  
            tests={member.tests || 3}
          />
        </Col>
      );
    });
  };

  const renderStatistics = () => {
    return (
      <Container className='p-2 text-center gitlab-statistics'>
        <Card className='about-vetsaid-card' >
          <Card.Title>    
            <h1 className="p-2 mt-2 text-center about-title">GitLab Statistics</h1>
          </Card.Title>
          <ListGroup>
            <ListGroupItem>Total Commits: {totalCommits} </ListGroupItem>
            <ListGroupItem>Total Issues: {totalIssues}</ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    );
  }

  const renderDocumentation = () => {
    return (
      <Container className='p-2 text-center '>
        <Card className='p-2'>
          <Card.Title>
            <h1 className="tools"> Come check out our code! </h1>
          </Card.Title>
          <Card.Body>
            <Row className='justify-content-center'>
              <Col xs={6} md={4} className='mb-2'>
                <Button variant="primary" href="https://documenter.getpostman.com/view/29974261/2s9YR9YY4K">API Documentation</Button>
              </Col>
              <Col xs={6} md={4} className='mb-2'>
                <Button variant="primary" href="https://gitlab.com/cs373-group-17/will_update_later">GitLab Repository</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>      
    );
  }
  
return (
  <Stack>
    <Container className='mt-3 p-3'>
      <Row className='justify-content-center align-items-center'>
        <Col>
        {renderDescription()}
        </Col>
        <Col>
          {renderStatistics()}
          {renderDocumentation()}
        </Col>
      </Row>
    </Container>

    <br></br>
    <Container className="p-1 text-center">
      <h1 className="meet_the_team text-center"> Meet the VeteranAid Team! </h1>
      <Container className="mt-5">
        <Row className='g-5 justify-content-center' xs={1} sm={2} md={3} xl={5}>
            {renderTeam()}
        </Row>
      </Container>
    </Container>

      <Container className="mt-5 p-3 text-center">
          <h1 className = "tools"> Tools Used </h1>
          <ToolGrid></ToolGrid>
      </Container>
    </Stack>
  )
}

export default About;