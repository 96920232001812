import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const MapContainer = (props) => {
  const mapStyles = {
    height: '50vh',
    width: '50%',
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCYDG4C36_4YjznNCsFtRyawRFqkTBE87Y">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={15}
        center={{ lat: props.lat, lng: props.lng }}
      >
        <Marker
          position={{ lat: props.lat, lng: props.lng }}
          title={props.name}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;