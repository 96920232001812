import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import RelatedInstanceComponent from '../instances/RelatedInstances';
import MapContainer from '../components/MapContainer';

const InstancePage = ({
    title,
    imgSrc,
    description,
    descriptors, // Pass an array of descriptor objects
    visitSiteLink,
    related_instance1,
    related_instance2,
    related_instance3,
    related_instance4,
    leftSectionText,
    rightSectionText,
    lat,
    long,
}) => {
    return (
        <Container className="InstancePage mt-5">
            <Row className="justify-content-center mb-4">
                <h1 className="display-4">{title}</h1>
            </Row>

            {/* Centered and enlarged image */}
            <Row className="justify-content-center mb-4">
                <Col md={8} className="text-center">
                    <img src={imgSrc} alt={title} className="img-fluid rounded shadow-lg" />
                </Col>
            </Row>

            <Row className="justify-content-center mb-4">
                <Col md={8}>
                    <p className="text-center font-weight-bold">{description}</p>
                </Col>
            </Row>

            <Row className="justify-content-center mb-4">
                <Col md={8}>
                    <Card className="p-4 shadow-sm">
                        {descriptors.map((item, index) => (
                            <p key={index}>
                                <strong>{item.descriptor}: </strong>
                                {item.field}
                            </p>
                        ))}
                    </Card>
                </Col>
            </Row>

            {/* MapContainer component */}
            <Row className="justify-content-center mb-4">
                <Col md={8} className="text-center">
                    <MapContainer lat={lat} lng={long} name={title} />
                </Col>
            </Row>

            <Row className="justify-content-center mb-4 mt-auto">
                <Col md={6} className="text-center">
                    <Button
                        href={visitSiteLink}
                        variant="primary"
                        size="lg"
                        className="btn-block shadow-lg mb-3"
                    >
                        Visit the Site
                    </Button>
                </Col>
            </Row>

            <Row className="justify-content-between mb-4">
                <RelatedInstanceComponent
                    leftHeaderText={leftSectionText}
                    rightHeaderText={rightSectionText}
                    leftCards={related_instance1}  
                    rightCards={related_instance2}
                 />
            </Row>
        </Container>
    );
};

InstancePage.propTypes = {
    title: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptors: PropTypes.arrayOf(
        PropTypes.shape({
            descriptor: PropTypes.string.isRequired,
            field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ).isRequired, // Pass an array of descriptor objects as a prop
    visitSiteLink: PropTypes.string.isRequired,
    related_instance1: PropTypes.node.isRequired,
    related_instance2: PropTypes.node.isRequired,
    related_instance3: PropTypes.node.isRequired,
    related_instance4: PropTypes.node.isRequired,
    leftSectionText: PropTypes.string.isRequired,
    rightSectionText: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
};

export default InstancePage;
