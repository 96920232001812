import React from 'react';
import {Card, Button, ListGroup, ListGroupItem} from 'react-bootstrap';
import "../styles/CardComp.css"
import { Link } from 'react-router-dom';
import '../styles/CardComp.css';

export const CardComp = ({ imgSrc, title, description, link }) => {
  return (
    <Card className="fixed-height-card" style={{ width: '18rem', height: '100%' }}>
      <Card.Img variant="top" src={imgSrc} style={{ height: '50%' }} />
      <Card.Body className="card-content" style={{ height: '40%', display: 'flex', flexDirection: 'column' }}>
        <Card.Title>{title}</Card.Title>
        <Card.Text style={{ flex: 1 }}>
          {description}
        </Card.Text>
        <div style={{ marginTop: 'auto' }}>
          <Button variant="primary" as={Link} to={link} style={{ alignSelf: 'flex-end' }}>Learn More</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

  export const PlayerCard = ({ imgSrc, name, role, description, commits, issues }) => {
    return (
      <Card className="mb-4 player-card" style={{width: '15rem'}}>
          <Card.Img className="mb-2 border border-secondary  player-card-img" variant="top" src={imgSrc} />
          <Card.Body>
              <Card.Title>{name}</Card.Title>
              <Card.Text>{[`Role: ${role}`]}</Card.Text>
              <Card.Text>{description}</Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush" style={{positionbottom:10}}>
                <ListGroupItem>{[`Commits: ${commits}`]}</ListGroupItem>
                <ListGroupItem>{[`Issues: ${issues}`]}</ListGroupItem>
                <ListGroupItem>{[`Tests: 0`]}</ListGroupItem>
          </ListGroup>
      </Card>
    );
}


// based off of cardcomp, using this for each user profile
export const ToolCard = ({ toolName, toolImage, toolUrl, toolDescription }) => (
  <Card className="fixed-height-card">
      <Card.Img className="mb-2 border border-secondary tool-image" variant="top" src={toolImage} alt={toolName} />
      <Card.Body>
          <Card.Title><a href={toolUrl} className="tool-link">{toolName}</a></Card.Title>
          <Card.Text className="tool-description">{toolDescription}</Card.Text>
      </Card.Body>
  </Card>
);

  export const ModelCards = ({
    imgSrc, 
    title,  
    link, 
    field1, 
    field2, 
    field3, 
    field4, 
    field5 
}) => {
    return (
        <div className = "model-cards" style={{ margin: '24 px' }}>
            {/* Assuming an image header */}
            <img src={imgSrc} alt={title} className="card-img-top" />

            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                {/* New fields */}
                <p className="card-field">{field1}</p>
                <p className="card-field">{field2}</p>
                <p className="card-field">{field3}</p>
                <p className="card-field">{field4}</p>
                <p className="card-field">{field5}</p>

                {/* Link button at the bottom */}
                <Link to={link} className="btn btn-primary">Learn More</Link>
            </div>
        </div>
    );
}

export default CardComp;