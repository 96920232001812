import aaron from "../images/aaron.png"
import ola from "../images/olamide.png"
import david from "../images/david.png"
import jonathan from "../images/jonathan.png"
import helial from "../images/helial.png"

const team = [
    {
        image: helial,
        name: "Helial Mordahl",
        alt_name: "",
        user: "helialm",
        display_name: "Helial Mordahl",
        user_id: 15580184,
        tests: 0,
        commits: 0,
        issues: 0,
        role: "Backend",
        bio: "I am a junior computer science major at UT Austin. In my free time, I enjoy reading, playing racquetball, and photography.",
        email: "helialmordahl@utexas.edu",
      },
    {
      image: aaron,
      name: "Aaron Posadas",
      alt_name: "root",
      user: "posadas.aaron",
      display_name: "Aaron Posadas",
      user_id: 15753000,
      role: "Fullstack",
      bio: "I'm a senior CS student studying in UT Austin. I volunteer and play volleyball in my free time.",
      tests: 0,
      commits: 0,
      issues: 0,
      email: "posadas.aaron@utexas.edu",
    },
    {
      image: david,
      name: "David Bockelman",
      alt_name: "",
      user: "davidbockelman",
      display_name: "David Bockelman",
      user_id: 13495626,
      role: "Backend",
      bio: "I'm a junior CS student studying in UT Austin. I like to play basketball and guitar in my free time.",
      tests: 5,
      commits: 0,
      issues: 0,
      email: "davidbockelman@utexas.edu",
    },
    {
        image: jonathan,
        name: "jonny512s",
        alt_name: "",
        display_name: "Jonathan Santibanez (jonny512s)",
        user: "jonnysantibanez2019",
        user_id: 15446309,
        tests: 0,
        commits: 0,
        issues: 0,
        role: "Backend & AWS",
        bio: "I am currently a Junior majoring in CS, with a double minor in Business and Economics. I train Muay Thai, lift weights, play basketball, cook, and watch anime in my free time",
        email: "jonny512@utexas.edu",
      },
    {
        image: ola,
        name: "olamideogunsanya",
        alt_name: "",
        user: "olamideogunsanya",
        display_name: "Olamide Ogunsanya",
        user_id: 15554668,
        tests: 18,
        commits: 0,
        issues: 0,
        role: "Fullstack",
        bio: "I am a junior CS and math student. In my free time I enjoy playing basketball, judo, and playing piano.",
        email: "olamideogunsanya@outlook.com",
      },
  ]

  export {team};