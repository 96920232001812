import React from 'react';
import { Container, Col, Row, Button, Form } from 'react-bootstrap';

const SortAndFilter = ({
    sortingOptions,
    setCurSort,
    setCurSortDirection,
    sortDirections,
    setSortDirections,
    selectedSort,
    setSelectedSort,
    filterOptions,
    setFilterAttribute,
    setFilterOperator,
    setFilterValue,
    setFilter,
    setSearchMode,
}) => {

    const handleSortDirection = (index) => {
        setSelectedSort(index)
        setSortDirections(sortDirections.map((dir, i) => {
            
            if (i === index && selectedSort === index) {
                setCurSortDirection(!dir)
                return !dir
            }
            if (i === index) {
                setCurSort(sortingOptions[index].param)
                setCurSortDirection(dir)
            }
            return dir
        }))
        setSearchMode(false)
    }

    const handleReset = () => {
        setSelectedSort(-1)
        setCurSortDirection(true)
        setSortDirections(sortDirections.map((dir, i) => {
            return true
        }))
    }

    return (
        
        <Container className="mt-4">
            <Row>
                <Col md={12} className="text-center" >
                    <h6>
                        Sorting
                    </h6>
                </Col>
            </Row>
            {
                sortingOptions.map((option, index) => {
                    return (
                        <Row key={index} style={{padding: 10}}>
                            <Col md={6} className="text-center">
                                {option.name}
                            </Col>
                            <Col md={6} className="text-center">
                                <Button variant={(index === selectedSort) ? ('primary') : ('secondary')}
                                        onClick={() => handleSortDirection(index)}>
                                {(sortDirections[index]) ? ('\u2B61') : ('\u2B63')}
                                </Button>
                            </Col>
                        </Row>
                    )
                })
            }
            <Row>
                <Col md={12} className="text-center" >
                    <Button variant="secondary" onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
            </Row>
            {filterOptions.length > 0 && <Row style={{paddingTop: 70}}>
                <Col md={12} className="text-center" >
                    <h6>
                        Filtering
                    </h6>
                </Col>
            </Row>}
            {
                filterOptions.map((option, index) => {
                    return (
                        <Row key={index} style={{padding: 10}}>
                            <Col md={6} className="text-center">
                                {option.name}
                            </Col>
                            <Col md={6} className="text-center">
                                {
                                    option.type === 'boolean' ? (
                                        <Form.Check
                                        type='checkbox'
                                        checked={option.checked[0]}
                                        onChange={() => {
                                            // option.checked[0] is the current value
                                            // option.checked[1] is the setter for option.checked[0]
                                            option.checked[1](!option.checked[0]); 
                                            console.log(option.checked[0])
                                            if (!option.checked[0]) {
                                                setFilterAttribute(option.param)
                                                setFilterOperator('eq')
                                                setFilterValue(option.val)
                                            }
                                            setFilter(!option.checked[0])
                                            setSearchMode(false)
                                        }}
                                        />

                                        
                                    ) : (
                                        <Button></Button>
                                    )
                                }
                            </Col>
                        </Row>
                    )
                })
            }
        </Container>
    )
}

export default SortAndFilter;