import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';

const ax = axios.create({
  baseURL: 'https://api.syrianrefugeecrisis.me/'
});

const RefugeesByCountry = () => {
  const [refugeesData, setRefugeesData] = useState([]);

  function ProcessRefugees(data) {
    return data.map(country => ({
      name: country.name, 
      refugees: country.num_refugees
    }));
  }

  useEffect(() => {
    const fetchRefugeesData = async () => {
      try {
        const response = await ax.get('countries');
        setRefugeesData(ProcessRefugees(response.data.data));
      } catch (error) {
        console.error('Error fetching refugees data:', error);
      }
    };

    fetchRefugeesData();
  }, []);

  return (
    <Container className="d-flex justify-content-center" fluid="md">
      <Row style={{ width: "100%" }}>
        <Col>
          <BarChart width={1200} height={400} data={refugeesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={-45} tick={{ fontSize: 9 }} textAnchor="end" interval={0} height={80} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="refugees" fill="#456CD9" />
          </BarChart>
        </Col>
      </Row>
    </Container>
  );
};

export default RefugeesByCountry;
