import React, { useEffect, useRef, useState } from 'react';
import { Row, Tab, Tabs, Container, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HousingCard from '../components/HousingCard';
import HealthCard from '../components/HealthCard';
import JobsCard from '../components/JobsCard';
import { loading } from '../util/loadingAnimation';

const Search = () => {
  const [housing, setHousing] = useState([]);
  const [housingLoaded, setHousingLoaded] = useState(false);
  const [housingNoResults, setHousingNoResults] = useState(false);

  const [healthFacilities, setHealthFacilities] = useState([]);
  const [healthLoaded, setHealthLoaded] = useState(false);
  const [healthNoResults, setHealthNoResults] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const [jobsNoResults, setJobsNoResults] = useState(false);

  const { query } = useParams();

  // Refs for current page number
  const housingPageRef = useRef(1);
  const healthPageRef = useRef(1);
  const jobsPageRef = useRef(1);

  // State for page triggers
  const [housingPageTrigger, setHousingPageTrigger] = useState(false);
  const [healthPageTrigger, setHealthPageTrigger] = useState(false);
  const [jobsPageTrigger, setJobsPageTrigger] = useState(false);

  function fetchData(modelName, setItems, setLoaded, setNoResults, pageRef) {
    setLoaded(false);
    // Updated URL to match the provided API documentation
    const url = new URL(`https://backend.vetsaid.me/api/search`);

    // Setting the search parameters according to the documentation
    url.searchParams.set('model', modelName);
    url.searchParams.set('query', query);
    url.searchParams.set('page[number]', pageRef.current);
    url.searchParams.set('page[size]', 10);

    fetch(url, {
      headers: {
        Accept: 'application/vnd.api+json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.data.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
          setItems(data.data);
          setLoaded(true);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoaded(true); // Ensure loaded is set even on error to stop loading animation
      });
  }

  // Fetch data for all tabs
  useEffect(() => {
    fetchData('housing', setHousing, setHousingLoaded, setHousingNoResults, housingPageRef);
    fetchData('healthcare', setHealthFacilities, setHealthLoaded, setHealthNoResults, healthPageRef);
    fetchData('jobs', setJobs, setJobsLoaded, setJobsNoResults, jobsPageRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // Fetch data when page triggers change
  useEffect(() => {
    if (housingPageTrigger) {
      fetchData('housingModelName', setHousing, setHousingLoaded, setHousingNoResults, housingPageRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [housingPageTrigger]);

  useEffect(() => {
    if (healthPageTrigger) {
      fetchData('healthcareModelName', setHealthFacilities, setHealthLoaded, setHealthNoResults, healthPageRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthPageTrigger]);

  useEffect(() => {
    if (jobsPageTrigger) {
      fetchData('jobsModelName', setJobs, setJobsLoaded, setJobsNoResults, jobsPageRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsPageTrigger]);

  return (
    <Container>
      <h1>Search Results</h1>
      <Tabs defaultActiveKey="housing">
        <Tab eventKey="housing" title="Housing">
          <h2>Housing Results</h2>
          {loading({
            loaded: housingLoaded,
            element: (
              <>
                {housingNoResults ? (
                  <p>Nothing matches your search.</p>
                ) : (
                  <Row>
                    {housing.map(item => (
                      <Col key={item.id} sm={12} md={6} lg={4} xl={3}>
                        <HousingCard 
                          address={item.attributes.address}
                          projectName={item.attributes.project_name}
                          numUnits={item.attributes.num_units}
                          borough={item.attributes.borough}
                          postcode={item.attributes.postcode}
                          id={item.id}
                          img_url={item.attributes.url}
                          searchQuery={query}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )
          })}
        </Tab>
        <Tab eventKey="health" title="Healthcare Facilities">
          <h2>Healthcare Results</h2>
          {loading({
            loaded: healthLoaded,
            element: (
              <>
                {healthNoResults ? (
                  <p>Nothing matches your search.</p>
                ) : (
                  <Row>
                    {healthFacilities.map(item => (
                      <Col key={item.id} sm={12} md={6} lg={4} xl={3}>
                        <HealthCard 
                          name={item.attributes.name}
                          classification={item.attributes.classification}
                          address={item.attributes.address}
                          city={item.attributes.city}
                          state={item.attributes.state}
                          id={item.id}
                          img_url={item.attributes.url}
                          searchQuery={query}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )
          })}
        </Tab>
        <Tab eventKey="jobs" title="Career Center">
          <h2>Job Results</h2>
          {loading({
            loaded: jobsLoaded,
            element: (
              <>
                {jobsNoResults ? (
                  <p>Nothing matches your search.</p>
                ) : (
                  <Row>
                    {jobs.map(item => (
                      <Col key={item.id} sm={12} md={6} lg={4} xl={3}>
                        <JobsCard 
                          title={item.attributes.title}
                          pay_freq={item.attributes.pay_frequency}
                          posting_date={item.attributes.posting_date}
                          location={item.attributes.location}
                          career_level={item.attributes.career_level}
                          id={item.id}
                          img_url={item.attributes.url}
                          searchQuery={query}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            )
          })}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Search;