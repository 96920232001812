import React, { useEffect, useState } from 'react';
import HousingCard from '../components/HousingCard.js';
import { Container, Row, Col, Button, Offcanvas, Form, FormControl } from 'react-bootstrap';
import PaginationComponent from '../components/PaginationComponent.js';
import SortAndFilter from '../components/SortAndFilter.js';
import '../styles/CardComp.css';

export const HousingPage = ({
    title,
    data,
    totalItems,
    itemsPerPage,
    currentPage,
    setPageNumber,
    setSort,
    setSortDirection,
    filterAttribute,
    setFilterAttribute,
    filterOperator,
    setFilterOperator,
    filterValue,
    setFilterValue,
    setFilter,
    setSearchQuery,
    setSearchMode
}) => {
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [highlightText, setHighlightText] = useState(''); // New state for highlighted text

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sortOptions = [
    { name: 'Completion Date', param: 'complete_date' },
    { name: 'Project Name', param: 'project' },
    { name: 'Extremely Low Income Units', param: 'eliu' },
    { name: 'Very Low Income Units', param: 'vliu' },
    { name: 'Low Income Units', param: 'liu' },
    { name: 'Total Units', param: 'total_units' },
  ];

  const [selectedSort, setSelectedSort] = useState(-1);

  const sortDirs = sortOptions.map(() => true);
  const [sortDirections, setSortDirections] = useState(sortDirs);
  
  const filterOptions = []; // Assuming you have filter options defined similar to sortOptions

  const handleSearch = () => {
    setFilter(false);
    setSearchQuery(searchInput);
    setHighlightText(searchInput); // Set the highlighted text
    setPageNumber(1);
    setSearchMode(true);
    setSearchInput('')
  };


  return (
    <div className="ModelPage" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Container className="mt-4 text-center">
        <h2>{title}</h2>
      </Container>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col md={3} className="text-center">
            <Button variant="primary" onClick={handleShow}>
              Sort and Filter Options
            </Button>
            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Sort and Filter Options</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <SortAndFilter
                  key={1}
                  sortingOptions={sortOptions}
                  setCurSort={setSort}
                  setCurSortDirection={setSortDirection}
                  sortDirections={sortDirections}
                  setSortDirections={setSortDirections}
                  selectedSort={selectedSort}
                  setSelectedSort={setSelectedSort}
                  filterOptions={filterOptions}
                  setFilterAttribute={setFilterAttribute}
                  setFilterOperator={setFilterOperator}
                  setFilterValue={setFilterValue}
                  setFilter={setFilter}
                  setSearchMode={setSearchMode}
                />
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col md={6} className="text-center">
            <PaginationComponent totalItems={totalItems} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setPageNumber} />
            <p>Found {totalItems} housing facilities</p>
            </Col>
          {/* Search Section */}
          <Col md={2} className="text-right">
            <Form>
              <Form.Control type='text' placeholder='Search Housing' value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}}/>
            </Form>
          </Col>
          <Col md={1} className="text-right">
            <Button variant="outline-primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row className="card-grid">
          {data && data.map((card) => (
            <HousingCard
              key={card.id}
              address={card.attributes.address}
              projectName={card.attributes.project}
              numUnits={card.attributes.total_units}
              borough={card.attributes.borough}
              postcode={card.attributes.postcode}
              id={card.id}
              img_url={card.attributes.url}
              searchQuery={highlightText}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HousingPage;
