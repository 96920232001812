//import 'bootstrap/dist/css/bootstrap.min.css'; 
import React from 'react'
import { Navbar, Form, FormControl, Button, Nav, Container } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";

//import '../styles/NavbarComp.css'

export const NavbarComp = () => {
    const navigate = useNavigate();

    const handleSubmit = event => {
      event.preventDefault();
      const input = event.target.querySelector('input');
      navigate(`/search/${input.value}`);
};

    
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand as={Link} to="/">VeteranAid</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                    <Nav.Link as={Link} to="/housing">Housing</Nav.Link>
                    <Nav.Link as={Link} to="/health">Healthcare Facilities</Nav.Link>
                    <Nav.Link as={Link} to="/jobs">Career Center</Nav.Link>
                    <Nav.Link as={Link} to="/visualizations "> Visualizations</Nav.Link>
                    <Nav.Link as={Link} to="/provider_visualizations "> Provider Visualizations</Nav.Link>
                </Nav>
                <Form onSubmit={handleSubmit} className="d-flex">
              <Form.Control className="custom" type="search" placeholder="Search" />
            </Form>

            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComp;
