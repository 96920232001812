import React, { useEffect, useState } from 'react';
import HealthPage from '../pages/HealthPage';

export const VeteranHealthcare = () => {
  const [healthData, setHealthData] = useState({ data: [], meta: { total: 0 } });
  const [currentPage, setCurrentPage] = useState(1);
  const [curSort, setSort] = useState('name');
  const [curSortDirection, setSortDirection] = useState(true);
  const [filterAttribute, setFilterAttribute] = useState('name');
  const [filterValue, setFilterValue] = useState('');
  const [filterOperator, setFilterOperator] = useState('eq');
  const [filter, setFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // Add searchQuery state
  const [searchMode, setSearchMode] = useState(false); // Add searchMode state

  // Function to fetch search results
  const fetchSearchResults = (model, query, currentPage) => {
    const apiUrl = `https://backend.vetsaid.me/api/search?model=${model}&query=${query}&page[number]=${currentPage}&page[size]=12`;
  
    return fetch(apiUrl, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        return jsonData; // Return the search results data
      })
      .catch((error) => {
        console.error('Error while fetching search results:', error);
        return null; // Return null in case of an error
      });
  };

  useEffect(() => {
    if (searchMode && searchQuery) {
      // Perform a search if searchQuery is not empty
      
      fetchSearchResults('healthcare', searchQuery, currentPage)
        .then((searchResults) => {
          if (searchResults) {
            // Handle the search results, e.g., set them in state
            setHealthData(searchResults);
          }
        })
        .catch((error) => {
          console.error('Error while fetching search results:', error);
        });
    } else {
      // If searchQuery is empty, fetch regular data based on filters, sorting, etc.
      fetch('https://backend.vetsaid.me/api/healthcare?page[size]=12&page[number]=' + currentPage
        + '&sort=' + (curSortDirection ? '' : '-') + curSort +
        (filter ? ('&filter[objects]=[{"name":"' + filterAttribute + '","op":"' + filterOperator + '","val":"' + filterValue + '"}]') : ''),
        { headers: { Accept: 'application/vnd.api+json' } })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((jsonResponse) => {
          setHealthData(jsonResponse);
          console.log(jsonResponse);
        })
        .catch((error) => {
          console.error('There has been a problem with your fetch operation:', error);
        });
    }
  }, [currentPage, curSort, curSortDirection, filter, filterAttribute, filterOperator, filterValue, searchQuery, searchMode]);

  return (
    <div className="VeteranHealthcare">
      <HealthPage
        title="Healthcare Facilities"
        data={healthData.data}
        totalItems={healthData.meta.total}
        itemsPerPage={12}
        currentPage={currentPage}
        setPageNumber={setCurrentPage}
        setSort={setSort}
        setSortDirection={setSortDirection}
        filterAttribute={filterAttribute}
        setFilterAttribute={setFilterAttribute}
        filterOperator={filterOperator}
        setFilterOperator={setFilterOperator}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setFilter={setFilter}
        setSearchQuery={setSearchQuery} // Pass setSearchQuery to HealthPage
        setSearchMode={setSearchMode} // Pass setSearchMode to HealthPage
      />
    </div>
  );
};

export default VeteranHealthcare;