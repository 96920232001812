import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { highlightText } from '../util/highlightText';

const JobsCard = ({
  title,
  pay_freq,
  posting_date,
  location,
  career_level,
  id,
  img_url,
  searchQuery, // New prop for the search query
}) => {
  const [imageURL, setImageURL] = useState('');
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    fetch(img_url)
      .then((response) => response.blob())
      .then((blob) => {
        var objectURL = URL.createObjectURL(blob);
        setImageURL(objectURL);
      })
      .catch((error) => {
        console.log(error);
      });

    const date = new Date(posting_date);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    setFormattedDate(date.toLocaleDateString(undefined, options));
  }, [img_url, posting_date]);

  return (
    <Card className="model-card" style={{ height: '400px' }}>
      <Card.Img variant="top" src={imageURL} style={{ height: '150px', objectFit: 'cover' }} />
      <Card.Body className="card-body d-flex flex-column justify-content-between">
      <div>
        <Card.Title style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {highlightText(title, searchQuery)}
        </Card.Title>
        <Card.Text>
          Pay Frequency: {highlightText(pay_freq, searchQuery)}<br />
          Posted: {highlightText(formattedDate, searchQuery)}<br />
          Career Level: {highlightText(career_level, searchQuery)}<br />
          Location: {highlightText(location, searchQuery)}
        </Card.Text>
        </div>
        <Link to={`/jobs/${id}`}>
          <Button variant="primary">Learn More</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default JobsCard;
