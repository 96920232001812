import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InstancePage from '../pages/InstancePage';
import JobsCard from '../components/JobsCard';
import HealthCard from '../components/HealthCard';
import {Row, Col} from "react-bootstrap"

export const HousingInstance = () => {
  let { instanceId } = useParams();
  const [instanceData, setInstanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [relatedHealthInstances, setRelatedHealthInstances] = useState([]);
  const [relatedJobsInstances, setRelatedJobsInstances] = useState([]);

  useEffect(() => {
    // Construct the URL with the instanceId
    const url = `https://backend.vetsaid.me/api/housing/${instanceId}`;

    fetch(url, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        setInstanceData(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Fetch error:', err);
        setError(true);
        setLoading(false);
      });

    // Generate random instance IDs between 1 and 30
    const randomHealthInstanceID = Math.floor(Math.random() * 30) + 1;
    const randomJobInstanceID = Math.floor(Math.random() * 30) + 1;

    // Construct the URLs with the random instance IDs
    const healthInstancesUrl = `https://backend.vetsaid.me/api/healthcare/${randomHealthInstanceID}`;
    const jobsInstancesUrl = `https://backend.vetsaid.me/api/jobs/${randomJobInstanceID}`;

    console.log('relatedHealthInstances:', relatedHealthInstances);
    console.log('relatedJobsInstances:', relatedJobsInstances);

    // Fetch related health instances data
    fetch(healthInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setRelatedHealthInstances(data);
      })
      .catch(error => {
        console.error('Error fetching related health instances:', error);
      });

    // Fetch related jobs instances data
    fetch(jobsInstancesUrl, { headers: { 'Accept': 'application/vnd.api+json' } })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setRelatedJobsInstances(data);
      })
      .catch(error => {
        console.error('Error fetching related jobs instances:', error);
      });


      console.log('relatedHealthInstances:', relatedHealthInstances);
      console.log('relatedJobsInstances:', relatedJobsInstances);
      
  }, [instanceId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !instanceData) {
    return <div>Instance not found</div>;
  }
  console.log(instanceData);



  var description = instanceData.data.attributes.address + ' is a housing property under the ' +
    instanceData.data.attributes.project + ' project. It is located in ' + instanceData.data.attributes.borough +
    ', New York City. It has ' + instanceData.data.attributes.total_units + ' total units, of which, ' +
    instanceData.data.attributes.eliu + ' are extremely low income units, ' +
    instanceData.data.attributes.vliu + ' are very low income units, and ' +
    instanceData.data.attributes.liu + ' are low income units.'

  // Create an array of descriptor objects (similar to previous code)
  const descriptors = [
    { descriptor: "Project", field: instanceData.data.attributes.project },
    { descriptor: "Address", field: instanceData.data.attributes.address },
    { descriptor: "Extremely Low Income Units", field: instanceData.data.attributes.eliu },
    { descriptor: "Very Low Income Units", field: instanceData.data.attributes.vliu },
    { descriptor: "Low Income Units", field: instanceData.data.attributes.liu },
  ];

  return (
  <div>
    <InstancePage
      title={instanceData.data.attributes.address}
      imgSrc={instanceData.data.attributes.url}
      description={description}
      descriptors={descriptors}
      leftSectionText="Nearby hospitals/clinics"
      rightSectionText="Nearby Jobs"
      visitSiteLink="#"
      lat={instanceData.data.attributes.latitude}
      long={instanceData.data.attributes.longitude}
      related_instance1={Array.isArray(relatedHealthInstances) ? relatedHealthInstances.map((healthInstance, index) => ({
        content: (
          <HealthCard
            key={index}
            name={healthInstance.data.attributes.name}
            classification={healthInstance.data.attributes.classification}
            address={healthInstance.data.attributes.address}
            city={healthInstance.data.attributes.city}
            state={healthInstance.data.attributes.state}
            id={healthInstance.data.attributes.id}
            img_url={healthInstance.data.attributes.img_url}
          />
        ),
      })) : []}
      related_instance2={Array.isArray(relatedHealthInstances) ? relatedHealthInstances.map((healthInstance, index) => ({
        content: (
          <HealthCard
            key={index}
            name={healthInstance.data.attributes.name}
            classification={healthInstance.data.attributes.classification}
            address={healthInstance.data.attributes.address}
            city={healthInstance.data.attributes.city}
            state={healthInstance.data.attributes.state}
            id={healthInstance.data.attributes.id}
            img_url={healthInstance.data.attributes.img_url}
          />
        ),
      })) : []}
      related_instance3={Array.isArray(relatedJobsInstances) ? relatedJobsInstances.map((jobInstance, index) => ({
        content: (
          <JobsCard
            key={index}
            title={jobInstance.data.attributes.title}
            pay_freq={jobInstance.data.attributes.pay_freq}
            posting_date={jobInstance.data.attributes.posting_date}
            location={jobInstance.data.attributes.location}
            career_level={jobInstance.data.attributes.career_level}
            id={jobInstance.data.attributes.id}
            img_url={jobInstance.data.attributes.img_url}
          />
        ),
      })) : []}
      related_instance4={Array.isArray(relatedJobsInstances) ? relatedJobsInstances.map((jobInstance, index) => ({
        content: (
          <JobsCard
            key={index}
            title={jobInstance.data.attributes.title}
            pay_freq={jobInstance.data.attributes.pay_freq}
            posting_date={jobInstance.data.attributes.posting_date}
            location={jobInstance.data.attributes.location}
            career_level={jobInstance.data.attributes.career_level}
            id={jobInstance.data.attributes.id}
            img_url={jobInstance.data.attributes.img_url}
          />
        ),
      })) : []}
    />
    <Row xs = {5}>
    <Col xs={5}>
        <JobsCard
             title= "CITY RESEARCH SCIENTIST"
             pay_freq = "Annual"
             posting_date = "2023-06-15T00:00:00.000"
             location = "96-05 Horace Harding Expway"
             career_level= "Experienced (non-manager)"
             id = "5"
             img_url= "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQzQVtfhxgaUsKulAVnqPTvJW5ryBODV_lxzSGnV252E6YDNpVrRiuaz68"
        />
        <JobsCard
            title= "JavaScript/ReactJS Developer"
            pay_freq = "Annual"
            posting_date = "2023-08-16T00:00:00.000"
            location = "9 Metrotech Center, Brooklyn N"
            career_level= "Experienced (non-manager)"
            id= "6"
            img_url = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQhdSjFlKXrxtMUggbckB7qPLvwE1Bc-2CuYWE5Gu0TUu7wSGSo-S_Mfde"
        />
    </Col>
    <Col xs={5}>
        <HealthCard
            id="5"
            name="Harlem VA Clinic"
            classification="Other Outpatient Services (OOS)"
            address="55 West 125th Street"
            city="New York"
            state="NY"
            img_url = "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRpIMbEp1epIOM7ps1FJLbB7rpq2r5k6OllStD0z7Ah-eZEWJ7Fj7c3jAA2"

        />
        <HealthCard
            id="6"
            name="Staten Island Community VA Clinic"
            classification="Multi-Specialty CBOC"
            address="1150 South Avenue"
            city="Staten Island"
            state="NY"
            img_url = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSqAbNQwkA2YF4IgBIPJDQIoVK_cYALJ_cZSdaLFdOPULER0LXEgzSPzOA"
        />
    </Col>
    </Row>
    </div>
  );
};

export default HousingInstance;