import React, { useState } from 'react';
import { Container, Row, Col, Button, Offcanvas, Form, FormControl } from 'react-bootstrap';
import PaginationComponent from '../components/PaginationComponent';
import JobsCard from '../components/JobsCard';
import SortAndFilter from '../components/SortAndFilter';
import "../styles/CardComp.css";
import SearchBar from '../components/SearchBar';

const JobsPage = ({
  title,
  data,
  totalItems,
  itemsPerPage,
  currentPage,
  setPageNumber,
  setSort,
  setSortDirection,
  filterAttribute,
  setFilterAttribute,
  filterOperator,
  setFilterOperator,
  filterValue,
  setFilterValue,
  setFilter,
  setSearchQuery,
  setSearchMode
}) => {

  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [highlightText, setHighlightText] = useState(''); // New state for highlighted text

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sortOptions = [
    { name: 'Title', param: 'title' },
    { name: 'Pay Low End', param: 'pay_low_end' },
    { name: 'Pay High End', param: 'pay_high_end' },
    { name: 'Posting Date', param: 'posting_date' },
    { name: 'Number of Openings', param: 'number_of_positions' },
  ];

  const [selectedSort, setSelectedSort] = useState(-1);
  const sortDirs = sortOptions.map(() => true);
  const [sortDirections, setSortDirections] = useState(sortDirs);

  const filterOptions = [
    {
      name: 'Hourly',
      type: 'boolean',
      param: 'pay_frequency',
      val: 'Hourly',
      checked: useState(false),
    },
    // ... other filter options
  ];
 
  const handleSearch = () => {
    setFilter(false);
    setSearchQuery(searchInput);
    setHighlightText(searchInput); // Set the highlighted text
    setPageNumber(1);
    setSearchMode(true);
    setSearchInput('')
  };

  return (
    <div className="JobsPage" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Container className="mt-4 text-center">
        <h2>{title}</h2>
      </Container>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col md={3} className="text-center">
            <Button variant="primary" onClick={handleShow}>
              Sort and Filter Options
            </Button>
            <Offcanvas show={show} onHide={handleClose} scroll={true} backdrop={false}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Sort and Filter Options</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <SortAndFilter
                  sortingOptions={sortOptions}
                  setCurSort={setSort}
                  setCurSortDirection={setSortDirection}
                  sortDirections={sortDirections}
                  setSortDirections={setSortDirections}
                  selectedSort={selectedSort}
                  setSelectedSort={setSelectedSort}
                  filterOptions={filterOptions}
                  setFilterAttribute={setFilterAttribute}
                  setFilterOperator={setFilterOperator}
                  setFilterValue={setFilterValue}
                  setFilter={setFilter}
                  setSearchMode={setSearchMode}

                />
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col md={6} className="text-center">
            <PaginationComponent
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setPageNumber}
            />
            <p>Found {totalItems} job postings</p>
          </Col>
          {/* Search Section */}
          <Col md={2} className="text-right">
            <Form>
              <Form.Control type='text' placeholder='Search Careers' value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}}/>
            </Form>
          </Col>
          <Col md={1} className="text-right">
            <Button variant="outline-primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row className="card-grid">
          {data && data.map((card) => (
            <JobsCard
              key={card.id}
              title={card.attributes.title}
              pay_freq={card.attributes.pay_frequency}
              posting_date={card.attributes.posting_date}
              career_level={card.attributes.career_level}
              location={card.attributes.location}
              id={card.id}
              img_url={card.attributes.url}
              searchQuery={highlightText} 
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default JobsPage;
