import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CharitiesByCountry from "../components/visualizations/CharitiesByCountry";
import { NewsAndEvents } from '../components/visualizations/NewsAndEvents';
import RefugeesByCountry from "../components/visualizations/RefugeesByCountry";

export const ProviderVisualizations = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h1>Number of charities per country</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12}>
          <CharitiesByCountry />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
          <h1>Number of refugees per country</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12}>
          <RefugeesByCountry />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} className="text-center">
            <h1 className="p-5 text-center">Countries Affected by Disaster According to News</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12}>
          <NewsAndEvents />
        </Col>
      </Row>
      
    </Container>
  )
};

export default ProviderVisualizations;
