import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { highlightText } from '../util/highlightText';

const HealthCard = ({ name, classification, address, city, state, id, img_url, searchQuery }) => {
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    fetch(img_url)
      .then((response) => response.blob())
      .then((blob) => {
        var objectURL = URL.createObjectURL(blob);
        setImageURL(objectURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [img_url]);

  return (
    <Card className="model-card" style={{ height: '400px' }}>
      <Card.Img variant="top" src={imageURL} style={{ height: '150px', objectFit: 'cover' }} />
      <Card.Body className="card-body d-flex flex-column justify-content-between">
      <div>
        <Card.Title style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
       
          {highlightText(name, searchQuery)}
        </Card.Title>
        <Card.Text>
          Classification: {highlightText(classification, searchQuery)}<br />
          Address: {highlightText(address, searchQuery)}<br />
          City: {highlightText(city, searchQuery)}<br />
          State: {highlightText(state, searchQuery)}<br />
        </Card.Text>
        </div>
        <Link to={`/health/${id}`}>
          <Button variant="primary">Learn More</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default HealthCard;
